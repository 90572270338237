export const team = [
  {
    name: 'Karin Lingg',
    id: 'karin',
    image: '',
    motivation:
      'Schmerzfreie Bewegung ist Glück und Freiheit für Körper und Geist.',
    ausbildung: [
      'Dipl Physiotherapeutin FH',
      `Dipl. Manualtherapeutin (Analytische Biomechanik nach Sohier)`,
      `Manuelle Triggerpunkttherapie MT1, MT2, MT3`,
      `Dipl. Manualtherapeutin (SAMT – Mobilisation mit und ohne Impuls)`,
      `Dipl. Sportphysiotherapeutin`,
      `Kinesiotape`,
      `Dipl. Dry Needling Therapeutin`,
      `Certified FRC Mobility Specialist (FRCms)`,
      `Fasziendistorsionsmodell FDM – Modul 1`,
      `Fasziendistorsionsmodell FDM – Modul 2`,
      `Gymnastics Injury Prevention`,
    ],
    behandlungsmethoden: [
      `Physiotherapie / Sportphysiotherapie / Rehabilitation`,
      `Manualtherapie (SAMT / Sohier)`,
      `Triggerpunkttherapie`,
      `Vitalfeldtherapie`,
      `Dryneedling`,
      `Kinesiotape`,
      `Fasziendistorsionsmodell`,
    ],
  },
  {
    name: 'Jana Reuter',
    id: 'jana',
    image: '',
    motivation:
      'Mit Erfahrung begleite ich Sie während Ihres Heilungsprozesses, damit Sie ein schmerzfreies Leben geniessen können.',
    ausbildung: [
      'Dipl. Physiotherapeutin',
      'Dipl. Dry Needling Therapeutin',
      'Triggerpunkttherapeutin',
      'Lymphdrainage nach Földi',
      'Kinesiotape',
      'Manuelle Therapie',
      'Weiterbildung: Das egoistische Immunsystem',
      'Selbsthypnose im Alltag',
    ],
    behandlungsmethoden: [
      'Triggerpunkt-Therapie',
      'Kinesio-Taping',
      'Manuelle Lymphdrainage nach Földi',
      'Manuelle Therapie',
      'Dry Needling',
      'Fasziendistorsionsmodell',
    ],
  },
  {
    name: 'Manuela Kaderli',
    id: 'manuela',
    image: '',
    motivation:
      'Take care of your body. It’s the only place you have to live..',
    ausbildung: [
      'Dipl. Sportphysiotherapeutin ESP',
      'Physiotherapeutin FH',
      'Dipl. Dry Needling Therapeutin',
      'Eidgenössischer Fachausweis Medizinische Masseurin 2012',
      'Funktions- und Mobilisationsmassage, Bodyfeet AG',
      'Grundausbildung der DORN-Methode, Bodyfeet AG',
      'Leukotape Pain Relief Technique Basis, Simon Keller AG',
      'Kopfschmerz- und Migränetherapie, Bodyfeet AG',
    ],
    behandlungsmethoden: [
      'Manuelle Lymphdrainage mit Bandagierung',
      'Dry Needling',
      'Klassische Massagen',
      'Bindegewebsmassagen',
      'Fussreflexzonentherapie',
      'Elektrotherapie',
      'Funktions- und Mobilisationsmassagen',
      'Grundausbildung in der DORN-Methode',
      'Leukotape Pain Relief Technique Basis',
      'Kopfschmerz- und Migränetherapie',
    ],
  },
  {
    name: 'Marc Grob',
    id: 'marc',
    image: '',
    motivation: 'Ein gesunder Geist wohnt in einem gesunden Körper',
    ausbildung: [
      'Dipl. Dry Needling Therapeut',
      'Dipl. Physiotherapeut FH',
      'Fortbildung: Grundkurs Taping',
      'Dry Needling',
      'FOI',
    ],
    behandlungsmethoden: [
      'Physiotherapie',
      'Dry Needling',
      'Kinesiotape',
      'Klassische Massage',
    ],
  },
  // {
  //   name: 'Samuel Blumer',
  //   id: 'samuel',
  //   image: '',
  //   motivation:
  //     'Schmerzfrei zu sein erfordert oft besonderen Einsatz. In diesem Prozess begleite ich sie mit Sorgfalt und Passion.',
  //   ausbildung: ['TCM-Therapeut'],
  //   behandlungsmethoden: [
  //     `Akupunktur`,
  //     `Klassische Massage`,
  //     `Tui Na Massage (manuelle Massage Technik der TCM)`,
  //     `Schröpfen`,
  //     `Maxibastion`,
  //     `Elektroakupunktur`,
  //     `Mykotherapie`,
  //     `Ernährungslehre`,
  //     `Faszialmanipulation`,
  //   ],
  // },
  {
    name: 'Philipp Mathis',
    id: 'philipp',
    image: '',
    motivation: 'Bewegen ist Leben.',
    ausbildung: ['Dipl. Physiotherapeut FH'],
    behandlungsmethoden: [`Dry Needling`],
  },
  {
    name: 'Sibylle Eckert',
    id: 'sibylle',
    image: '',
    motivation:
      'Mein Ziel ist es, den Menschen durch Therapie und Bewegung ein Bewusstsein für ihren Körper zu geben, um ihre Ziele zu erreichen.',
    ausbildung: [
      'Dry Needling Top 30',
      'Dry Needling Lower Body Advanced',
      'Osteopathische Therapeutin',
      'Reiki Master',
      'Manuelle Therapie',
      'Craniosacrale Therapie COS',
      'Viszerale Therapie VFO',
      'Craniomadibulare Dysfunktion (Kiefer) CMD',
      'Faszienearbeit in der Osteopatischen Therapie FAO',
    ],
    behandlungsmethoden: [
      'Instruktorin für Purna Yoga',
      'Sporttherapeutin',
      'Massagetherapeutin',
      'Ernährungsberatung',
      'Elektrotherapie',
      'Trainingslehre',
      'Kinesiotaping',
    ],
  },
  {
    name: 'Muriel Williams',
    id: 'muriel',
    image: '',
    motivation:
      'Eine Reise von tausend Meilen beginnt mit einem kleinen Schritt.',
    ausbildung: [
      'Dipl. Phyisotherapeutin FH',
      'Dry Needling Top 30',
      'Dipl. Dry Needling Therapeutin',
      'Lymphdrainage',
      'Rückbildung Pilates',
      'Pilates Teacher',
    ],
    behandlungsmethoden: [
      'Lymphdrainage',
      'Triggerpunkttherapie',
      'Dry Needling',
      'Manuelle Therapie',
      'Pilates',
    ],
  },
  {
    name: 'Hanne Dupas',
    id: 'hanne',
    image: '',
    motivation: 'From pain to power. Ich bin hier, um dir dabei zu helfen.',
    ausbildung: [
      'Dipl. Physiotherapeutin',
      'Osteopathie MSc (Master of Science)',
      'Dry Needling Top 30',
      'Craniosakrale Therapie Modul 1 & 2, Upledger Institut Belgien',
      'Mental Coach Thomas More',
      'Yoga teacher',
      'Group facilitator',
    ],
    behandlungsmethoden: [
      'Osteopathische Techniken',
      'Manuelle Therapie',
      'Craniosakrale Therapie',
      'Viszerale Behandlungsmethoden',
      'Dry Needling',
      'Aktive Trainingstherapie',
      'Psychosomatisch: Regulierung des Nervensystems mit Übungen für zu Hause',
    ],
  },

  // {
  //   name: 'Elli Stenfors',
  //   id: 'elli',
  //   image: '',
  //   motivation:
  //     'Your health affects everything in your life and everything affects your health. I help you feel better in your everyday life.',
  //   ausbildung: [
  //     'Dipl. Phyisotherapeutin',
  //     'Dry Needling',
  //     'CrossFit level 1 trainer',
  //   ],
  //   behandlungsmethoden: [
  //     'Physiotherapie',
  //     'Klassische massage',
  //     'Dry Needling',
  //     'Kinesiotaping',
  //     'Leistungs- und Sportphysiotherapie',
  //     'Faszientherapie',
  //   ],
  // },
]
